module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag-optin/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-115029116-1"],"gtagConfig":{"optimize_id":"GTM-KHPQJV3","cookie_expires":0},"pluginConfig":{"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"24. Filmový festival Fakulty informatiky","titleTemplate":"%s | FFFIMU2024","description":"24. Filmový festival Fakulty informatiky MU se uskuteční v pátek 17. 5. 2024","language":"cs-CZ","canonical":"https://festival.fi.muni.cz","openGraph":{"title":"24. Filmový festival Fakulty informatiky","description":"24. Filmový festival Fakulty informatiky MU se uskuteční v pátek 17. 5. 2024","type":"website","locale":"cs-CZ","url":"https://festival.fi.muni.cz","site_name":"24. Filmový festival Fakulty informatiky"},"twitter":{"handle":"@handle","site":"@site","cardType":"summary_large_image","card":"summary"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/builds/lemma/web/festival-24","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"short_name":"FFFIMU2024","start_url":"/","background_color":"#0a020f","theme_color":"#0a020f","display":"minimal-ui","icon":"static/images/logo_24.jpg","legacy":true,"icons":[{"src":"favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"favicons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a7152da0c2beb00bb66b96151f18fdc5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
